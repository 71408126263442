<template>
  <div class="component">
    <van-action-sheet v-model="show" title="添加小程序附件">
      <div class="content" style="color: #818181;">

        <div class="user-list">
          <div class="ttips">
            <van-icon style="color: #1890ff;font-size: 14px" name="bell"/>
            <span>请填写企业微信后台绑定的小程序id和路径，否则会造成发送失败</span>
          </div>

          <van-field required :error="!items.title" v-model="items.title" input-align="right" label="小程序标题："
                     placeholder="请输入小程序标题"/>
          <van-field required :error="!items.description" v-model="items.description" input-align="right"
                     label="小程序appID：" placeholder="小程序appID"/>
          <van-field required :error="!items.imageLink" v-model="items.imageLink" input-align="right" label="小程序路径："
                     placeholder="请输入小程序路径"/>
          <van-cell required title="小程序封面">
            <van-uploader :after-read="afterRead" v-model="items.fileList" :max-size="2000 * 1024" @oversize="onOversize"
                          multiple :max-count="1"/>
          </van-cell>
        </div>

        <van-button @click="determine" block style="width: 90%;margin: 0 auto;margin-top: 10px;margin-bottom: 10px"
                    type="info">确定
        </van-button>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import {store, upload} from '../../../api/contactWelcome'

export default {
  data() {
    return {
      show: false,
      items: {
        title: '',
        description: '',
        imageLink: '',
        fileList: [],
      }
    }
  },
  watch: {},
  methods: {
    $show(data) {
      this.show = true
      this.items = {
        title: '',
        description: '',
        imageLink: '',
        fileList: [],
      }
      if(data.edit){
        this.data = data
        this.items = data.content
        this.index = data.index
      }else{
        this.data = data
      }
    },
    determine(e) {
      if (this.items.fileList.length <=0) {
        this.$message.warning('请选择小程序封面');
        this.show = false
        return
      }else{
        store({
          content: {
            title: this.items.title,
            titleApplet: this.items.title,
            appid: this.items.description,
            page: this.items.description,
            imagePath: this.items.fileList[0].path,
            imageName: this.items.fileList[0].name
          },
          mediumGroupId: 0,
          type: 6
        }).then(res => {
          const data = {
            content: {
              title: this.items.title,
              description: this.items.description,
              imageLink: this.items.imageLink,
              fileList: this.items.fileList,
              imagePath: this.items.fileList[0].path,
              id:res.data.id,
              type: '【小程序】：',
              types: 1
            },
            data:this.data,
          }
          this.$emit("change", data)
          this.show = false
        })
      }
    },
    // 文件大小
    onOversize(file) {
      this.$message.warning('文件大小不能超过 2MB');
    },
    // 上传文件
    afterRead(file) {
      var formData = new FormData();
      formData.append('file', file.file);
      upload(formData).then(res => {
        // this.items.title = res.data.name
        this.items.fileList[0] = {
          url: res.data.fullPath,
          path: res.data.path,
          name: res.data.name
        }
      })
    }

  }
}
</script>

<style lang="less" scoped>
/deep/ .van-empty__image {
  width: 129px !important;
  height: 115px !important;
}

/deep/ .van-action-sheet__header {
  font-weight: bolder;
}

.component {
  .content {
    padding: 0;

    .ttips {
      /deep/ .van-icon {
        font-size: 14px !important;
        color: #1890ff !important;
        margin-right: 5px !important;
      }

      font-size: 12px;
      padding: 5px 14px;
      background: #effaff;
      margin: 7px 7px;
    }

    .user-list {
      height: 275px;
      overflow: auto;

      .user {
        display: flex;
        align-items: center;
        justify-content: space-between;

        div {
          font-size: 14px;
          color: #252528;

          img {
            width: 46px;
            height: 46px;
            margin-right: 6px;
          }
        }
      }

      .active-background {
        background: #f6fbff !important;
      }
    }
  }
}
</style>
